const config = {
    title: "Umim sportovat"
}

const getSchemaOrgJSONLD = ({
    type,
    url,
    title,
    image,
    description,
    datePublished,
    dateUpdated,
    data,
    domain
}) => {
    switch (type) {
        case "WebPage":
            return {
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                url,
                name: title,
                alternateName: config.title,
                lastReviewed: dateUpdated,
                dateCreated: datePublished,
                headline: title,
                primaryImageOfPage: {
                    contentUrl: image
                }, 
                thumbnailUrl: image,
                description: description
            }
        case "SportsTeam": 
            return {
                '@context': 'http://schema.org',
                '@type': 'SportsTeam',
                sport: data.sport,
                athlete: data.athletes.map(a => ({
                    "@type": 'Person',
                    ...a
                })),
                numberOfEmployees: {
                    value: data.athletes.length
                },
                sameAs: `//www.${domain}.cz`
            }
        case "Offer": 
            return {
                '@context': 'http://schema.org',
                '@type': 'Service',
                ...data
            }
        case "Cooperation": 
            return {
                '@context': 'http://schema.org',
                '@type': 'Organization',
                sameAs: `//www.${domain}.cz`,
                memberOf: data
            }
        case "Review":
            return {
                '@context': 'http://schema.org',
                '@type': 'Organization',
                sameAs: `//www.${domain}.cz`,
                ...data
            }
        default:
            return {
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                url,
                name: title,
                alternateName: config.title,
                lastReviewed: dateUpdated,
                dateCreated: datePublished,
                headline: title,
                primaryImageOfPage: {
                    contentUrl: image
                }, 
                thumbnailUrl: image,
                description: description
            }
    }
  
};

export default getSchemaOrgJSONLD