/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import getSchemaOrgJSONLD from './schemaJsonSupport'

function SEO({ description, lang, meta, title, url, updated, created, schema = false, type, data }) {

  const { site, strapi } = useStaticQuery(
    graphql`
      query SeoQuery {
        site {
          siteMetadata {
            title
            description
            author
            domain
          }
        }
        strapi {
          domenas {
            Text
            Tagy
            Nazev
            hlavni_obrazek {
              url
            }
          }
        }
      }
    `
  )

  const domain = site.siteMetadata.domain
  const selectedDomain = strapi.domenas.find(d => d.Nazev === domain)

  const metaDescription = description || selectedDomain?.Text || site.siteMetadata.description
  const metaKeywords = selectedDomain?.Tagy
  const metaImage = selectedDomain?.hlavni_obrazek.url

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    url,
    title: title || site.siteMetadata.title,
    image : metaImage,
    description : metaDescription, 
    datePublished: created,
    dateUpdated: updated,
    type,
    data,
    domain
  });

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url || "",
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title || site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords
        }
      ].concat(meta)}
    >
      {schema && 
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `cs`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
